import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { viewUserProfile } from '../user-profile-list/core/_requests';

interface EducationDetail {
  certificateName: string;
  certificateNo: string;
  expiryDate: string;
  organisation: string;
}

export function ViewEducation() {
  const { id } = useParams();
  const [education, setEducation] = useState<EducationDetail[]>([]);

  useEffect(() => {
    viewUserProfile(id).then((res) => {
      // console.log('Fetched education data:', res.Data.education);
      setEducation(res.Data.education || []);
    });
  }, [id]);

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Education Details</h3>
        </div>
      </div>
      <div className='card-body p-9'>
        {education.length > 0 ? (
          education.map((edu, index) => (
            <div key={index}>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Certificate Name</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder text-dark'>{edu.certificateName}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Certificate No</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder text-dark'>{edu.certificateNo}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Expiry Date</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder text-dark'>{edu.expiryDate ? new Date(edu.expiryDate).toLocaleDateString('en-CA') : ''}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Organisation</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder text-dark'>{edu.organisation}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No records found.</p>
        )}
      </div>
    </div>
  );
}
