import {lazy, FC, Suspense, useState, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {getRolewiseRoutes} from './_requests'
import {routeRedirectSidemenu} from '../modules/apps/role-access/role-setting-list/core/_requests'
import AccountPage from '../modules/accounts/AccountPage'
import PasswordPage from '../modules/accounts/PasswordPage'
import UserProfilePage from '../modules/apps/user-profile/UserProfilePage'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()
  const [appRouteAccess, setappRouteAccess] = useState<any>([])
  const [routePermission, setroutePermission] = useState<any>([])
  const currentRole = currentUser?.roleId
  useEffect(() => {
    routeRedirectSidemenu(currentRole).then((res: any) => {
      //console.log('sidemenu', res.data.Data)
      const sidemenuitems = res.data.Data
      const modules = sidemenuitems.map((response: {module: any}) => response.module.link)
      setroutePermission(modules)
    })
  }, [])
  useEffect(() => {
    getRolewiseRoutes(currentUser?.roleId).then((res: any) => {
      var crudres = res.data?.Data[0]
      setappRouteAccess(crudres)
    })
  }, [])
  // console.log('routePermission', routePermission)
  const RolePage = lazy(() => import('../modules/apps/role/RolePage'))
  const EmployeePage = lazy(() => import('../modules/apps/employee/EmployeePage'))
  const CategoryPage = lazy(() => import('../modules/apps/category/CategoryPage'))
  const RoleaccessPage = lazy(() => import('../modules/apps/role-access/RoleaccessPage'))
  const ProfilePage = lazy(() => import('../modules/apps/profile/ProfilePage'))
  const AccessDeniedPage = lazy(() => import('../modules/apps/access-denied/AccessDeniedPage'))
  return (
    <Suspense fallback={<SuspendedView />}>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />

          <Route
            path='role/*'
            element={
              <SuspendedView>
                {routePermission.includes('/role') ? <RolePage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='employee/*'
            element={
              <SuspendedView>
                {routePermission.includes('/employee') ? <EmployeePage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='category/*'
            element={
              <SuspendedView>
                {routePermission.includes('/category') ? <CategoryPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='page-access/*'
            element={
              <SuspendedView>
                {routePermission.includes('/page-access') ? (
                  <RoleaccessPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          {/* <Route
            path='user-profile/*'
            element={
              <SuspendedView>
                {routePermission.includes('/user-profile') ? <ProfilePage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          /> */}
          <Route
            path='user-profile/*'
            element={
              <SuspendedView>
                {routePermission.includes('/user-profile') ? (
                  <UserProfilePage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='profile/*'
            element={
              <SuspendedView>
                <AccountPage />
              </SuspendedView>
            }
          />
          <Route
            path='changePassword/*'
            element={
              <SuspendedView>
                <PasswordPage />
              </SuspendedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

const SuspendedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
