import {useListView} from '../../core/ListViewProvider'
import {UserProfileListSearchComponent} from './UserProfileListSearchComponent'
import {UserProfileListToolbar} from './UserProfileListToolbar'

const UserProfileListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <UserProfileListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <UserProfileListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UserProfileListHeader}
