import React, {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {UserProfile} from '../user-profile-list/core/_models'

const imageUrl = process.env.REACT_APP_IMAGE_UPLOADS

const UserProfileView: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const [role, setRole] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [formImage, setFormImage] = useState<File | null>(null)

  //Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  return (
    <div className='card mb-5 mb-xl-10 p-9'>
      <h2> User Profile Details</h2>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button type='submit' className='btn btn-primary' disabled={loading}>
          {!loading && 'Save Changes'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default UserProfileView
