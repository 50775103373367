import React, { FC, useContext, useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../auth";
import { AlertContext } from "../../../../../_metronic/layout/components/alert-context";
import { UserProfile } from "../user-profile-list/core/_models";
import { viewUserProfile } from "../user-profile-list/core/_requests";

interface WorkedOrganisations {
  endMonth: string;
  endYear: any;
  internLocation: string;
  internPeriod: any;
  internRole: string;
  isCurrentlyWorking: any;
  location: string;
  organisation: string;
  role: string;
  startMonth: string;
  startYear: string;
  workType: string;
}
export function ViewWorkExperience() {
  const { id } = useParams();
  const [profile, setProfile] = useState<WorkedOrganisations[]>([]);

  useEffect(() => {
    viewUserProfile(id).then((res: any) => {
      // console.log("ProfileHeaderView====", res);
      setProfile(res.Data.workedorganisations || []);
    });
  }, []);

  // console.log("profile=====Experience", profile);

  return (
    <>
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-header cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Work Experience Details</h3>
          </div>
        </div>
        <div className="card-body p-9">
          {profile.length > 0 ? (
            profile.map((pro, index) => (
              <div key={index}>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">Role</label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.internRole}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Organisation
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.organisation}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Location
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.internLocation}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Work Type
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.workType}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Experience Start
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.startMonth}{pro?.startYear ? `/${pro?.endYear}` : ""}
                    </span>
                  </div>
                </div>
                {/* <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Experience in years
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.experienceYears
                        ? profile?.experienceYears
                        : "" + profile?.experienceYears
                        ? " Years and "
                        : "" + profile?.experienceMonths
                        ? profile?.experienceMonths
                        : "" + profile?.experienceMonths
                        ? " Months "
                        : ""}
                    </span>
                  </div>
                </div> */}
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Experience End
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.endMonth}{pro?.endYear ? `/${pro?.endYear}` : ""}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Intern Location
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.internLocation}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Intern Role
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.internRole}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Intern Period
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.internPeriod}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Currently Working
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {pro?.isCurrentlyWorking == true ? "yes" : "no"}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No records.</p>
          )}
        </div>
      </div>
    </>
  );
}
