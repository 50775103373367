// @ts-nocheck
import { Column } from "react-table";
import { UserProfileInfoCell, UserType, CreateProfileDate } from "./UserProfileInfoCell";
import { UserProfileTwoStepsCell } from "./UserProfileTwoStepsCell";
import { UserProfileActionsCell } from "./UserProfileActionsCell";
import { UserProfileCustomHeader } from "./UserProfileCustomHeader";
import { UserProfile } from "../../core/_models";

const UserProfileColumns: ReadonlyArray<Column<UserProfile>> = [
  {
    Header: (props) => (
      <UserProfileCustomHeader
        tableProps={props}
        title="S.No"
        className="min-w-125px"
      />
    ),
    accessor: "serialNo",
  },
  {
    Header: (props) => (
      <UserProfileCustomHeader
        tableProps={props}
        title="Name"
        className="min-w-125px"
      />
    ),
    accessor: "firstName",
    Cell: ({ ...props }) => (
      <UserProfileInfoCell userprofiles={props.data[props.row.index]} />
    ),
  },

  {
    Header: (props) => (
      <UserProfileCustomHeader
        tableProps={props}
        title="Mobile"
        className="min-w-125px"
      />
    ),
    accessor: "mobile",
  },

  {
    Header: (props) => (
      <UserProfileCustomHeader
        tableProps={props}
        title="Type"
        className="min-w-125px"
      />
    ),
    accessor: "categoryId",
    Cell: ({ ...props }) => (
      <UserType usertype={props.data[props.row.index]} />
    ),
  },

  {
    Header: (props) => (
      <UserProfileCustomHeader
        tableProps={props}
        title="Date"
        className="min-w-125px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => <CreateProfileDate usercreateprofile={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <UserProfileCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <UserProfileActionsCell userprofiles={props.data[props.row.index]} />
    ),
  },
];

export { UserProfileColumns };
