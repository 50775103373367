import React, { FC, useContext, useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../auth";
import { AlertContext } from "../../../../../_metronic/layout/components/alert-context";
import { UserProfile } from "../user-profile-list/core/_models";
import { getRole } from "../../role-access/role-setting-list/core/_requests";
import { viewUserProfile } from "../user-profile-list/core/_requests";
import Avatar from "react-avatar";

const UserProfileView: React.FC = () => {
  const { showAlert } = useContext(AlertContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [role, setRole] = useState<any>([]);
  const [profile, setProfile] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [formImage, setFormImage] = useState<File | null>(null);

  const imageUrl = process.env.REACT_APP_IMAGE_UPLOADS;

  //Image Target
  interface Event<T = EventTarget> {
    target: T;
    // ...
  }

  useEffect(() => {
    getRole().then((res: any) => {
      setRole(res?.data);
    });
  }, []);

  useEffect(() => {
    viewUserProfile(id).then((res: any) => {
      //   console.log('ProfileHeaderView====', res)
      setProfile(res.Data);
    });
  }, []);

  var downloadResume = profile?.resume;

  var view = "/user-profile/view/" + id;
  var education = "/user-profile/education/" + id;
  var skills = "/user-profile/skills/" + id;
  var workOrganisation = "/user-profile/work-organisation/" + id;
  var workExperience = "/user-profile/work-experience/" + id;
  var editProfile = "/user-profile/edit/" + id;

  // console.log("profile==========", profile);
  return (
    <>
      <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-9 pb-0">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                {profile?.profile ? (
                  <div className="symbol symbol-100px symbol-lg-140px symbol-fixed position-relative">
                    <img
                      src={profile?.profile}
                      alt="ProfileImage"
                      style={{ objectFit: "contain" }}
                      className="w-100"
                    />
                  </div>
                ) : (
                  <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                    <Avatar
                      name={profile?.firstName}
                      maxInitials={2}
                      round
                      color="#607458"
                      fgColor="#1b1b29"
                    ></Avatar>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="col-xl-9 col-lg-9 col-md-9">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-2 mx-4">
                      <a className="text-gray-800 fs-2 fw-bolder me-1">
                        {profile?.firstName}
                        {profile?.aboutUser ? ` (${profile.aboutUser})` : ""}
                      </a>
                    </div>

                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                      {profile?.categoryId === 1 ? (
                        <a className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3">
                          Fresher
                        </a>
                      ) : profile?.categoryId === 2 ? (
                        <a className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3">
                          Experience
                        </a>
                      ) : (
                        ""
                      )}
                      {/* <a className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'>
                      {profile?.categoryId === 1
                        ? 'Fresher'
                        : profile?.categoryId === 2
                        ? 'experience'
                        : ''}
                    </a> */}
                    </div>
                  </div>
                </div>
                {/* {roleaccess?.edit === 1 ? (
                  <Link to={editProfile} className='btn btn-primary align-self-center'>
                    Edit Profile
                  </Link>
                ) : (
                  ''
                )} */}
                <div className="d-flex align-items-center position-relative my-1">
                  <Link
                    to={downloadResume}
                    className="btn btn-primary align-self-center"
                  >
                    Download Resume
                  </Link>
                </div>
              </div>
              <div className="d-flex flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        {/* <KTIcon iconName="multimedia" className="fs-3" /> */}
                        <i className="fas fa-phone text-success mr-5 me-2"></i>
                        <div className="fs-7 fw-bolder">{profile?.mobile}</div>
                      </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        {/* <KTIcon iconName=" " className="fs-3" /> */}
                        <i className="fas fa-envelope text-success mr-5 me-2"></i>
                        <div className="fs-7 fw-bolder">{profile?.emailId}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex overflow-auto h-55px">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className="nav-item">
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === view && "active")
                  }
                  to={view}
                >
                  Basic Details
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === education && "active")
                  }
                  to={education}
                >
                  Education
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === skills && "active")
                  }
                  to={skills}
                >
                  Skills
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === workExperience && "active")
                  }
                  to={workExperience}
                >
                  Work Experience
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === workOrganisation && "active")
                  }
                  to={workOrganisation}
                >
                  Work Authorization
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileView;
