/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {deleteUserProfileById} from '../../core/_requests'
import {UserProfile} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {useAuth} from '../../../../../auth'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'

type Props = {
  userprofiles: UserProfile
}

const UserProfileActionsCell: FC<Props> = ({userprofiles}) => {
  console.log('useAuth===', useAuth)
  console.log('userprofiles===', userprofiles)
  const {showAlert} = useContext(AlertContext)
  // const {userprofile} = useAuth();
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const [roleaccess, setRoleAccess] = useState(false)
  const handleClose = () => setShow(false)

  // useEffect(()=>{
  //   getExCRUD(userprofiles?.roleId, 4).then(res: any)=>{
  //     console.log("response accress====",res);
  //     var crudres = res.data?.data;
  //     setRoleAccess(crudres);
  //   }
  // });

  const handleShow = () => {
    setShow(true)
  }

  const ViewUserProfileDetail = () => {
    navigate('/user-profile/view/' + userprofiles?.id)
  }

  const deleteItem = () =>
    deleteUserProfileById(userprofiles?.id).then((res) => {
      showAlert(res?.Message, res?.Code)
      setShow(false)
      navigate('/user-profile')
    })

  return (
    <>
      {/* Edit Button */}
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          onClick={ViewUserProfileDetail}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
          <KTSVG path='/media/icons/duotune/general/eye.svg' className='svg-icon-3' />
        </a>

        {/* Delete Button */}
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{userprofiles.name}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {UserProfileActionsCell}
