import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { viewUserProfile } from "../user-profile-list/core/_requests";

// Type definitions for the user profile (simplified)
interface UserProfile {
  authorizedUs: boolean;
  visaStatus: string;
  workOnFort: boolean;
  contractWithFort: boolean;
  countryWorkedFor: string;
  startYear: string; // Assuming startYear is a string for display
}

export const ViewWorkOrganisation: FC = () => {
  const { id } = useParams<{ id: string }>(); // Assure id is typed correctly
  const [profile, setProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await viewUserProfile(id);
        console.log("Profile fetched:", res);
        setProfile(res.Data);
      } catch (error) {
        console.error("Failed to fetch profile", error);
        // Optionally, handle errors, e.g., show an alert or a message
      }
    };

    fetchData();
  }, [id]);

  // Helper to convert boolean to Yes/No
  const booleanToYesNo = (value: boolean) => (value ? "Yes" : "No");

  return (
    <>
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-header cursor-pointer">
          <h3 className="card-title m-0 fw-bolder">Visa Details</h3>
        </div>
        <div className="card-body p-9">
          {profile ? (
            <>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  Authorized Us
                </label>
                <div className="col-lg-8">
                  <span className="fw-bolder text-dark">
                    {booleanToYesNo(profile.authorizedUs)}
                  </span>
                </div>
              </div>

              {/* Only show the following sections if `authorizedUs` is true */}
              {profile.authorizedUs && (
                <>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      Visa Status
                    </label>
                    <div className="col-lg-8">
                      <span className="fw-bolder text-dark">
                        {profile.visaStatus}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      Work On Fort
                    </label>
                    <div className="col-lg-8">
                      <span className="fw-bolder text-dark">
                        {booleanToYesNo(profile.workOnFort)}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      Contract With Fort
                    </label>
                    <div className="col-lg-8">
                      <span className="fw-bolder text-dark">
                        {booleanToYesNo(profile.contractWithFort)}
                      </span>
                    </div>
                  </div>
                </>
              )}

              {/* Only show this section if `authorizedUs` is false */}
              {!profile.authorizedUs && (
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    Country Worked For
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder text-dark">
                      {profile.countryWorkedFor}{" "}
                      {profile?.startYear ? `/ ${profile?.startYear}` : ""}
                    </span>
                  </div>
                </div>
              )}
            </>
          ) : (
            <p>Loading profile...</p> // Show loading or no data message
          )}
        </div>
      </div>
    </>
  );
};
