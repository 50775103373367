import { Route, Routes, Outlet, Navigate, useNavigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { UserProfileListWrapper } from "./user-profile-list/UserProfileList";
import UserProfileView from "./user-profile-form/UserProfileView";
import { useContext, useEffect } from "react";
import { AlertContext } from "../../../../_metronic/layout/components/alert-context";
import ProfileHeader from "./user-profile-form/ProfileHeader";
import AddProfile from "./user-profile-form/AddProfile";
import EditBasicDetailsWrapper from "./user-profile-form/EditBasicDetailsWrapper";
import { ViewEducation } from "./user-profile-form/UserEducation";
import { ViewSkills } from "./user-profile-form/UserSkills";
import { ViewWorkOrganisation } from "./user-profile-form/WorkOrganisation";
import { ViewWorkExperience } from "./user-profile-form/WorkExperience";

const UserProfilesBreadcrumbs: Array<PageLink> = [
  {
    title: "User Profile Management",
    path: "/user-profile",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ProfilePage = () => {
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }

  const { hideAlert } = useContext(AlertContext);

  useEffect(() => {
    hideAlert(false);
  }, []);

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
              <PageTitle breadcrumbs={UserProfilesBreadcrumbs}>
                User Profile Management
              </PageTitle>
              <UserProfileListWrapper />
            </>
          }
        />
      </Route>

      <Route
        path="/add"
        element={
          <>
            <PageTitle breadcrumbs={UserProfilesBreadcrumbs}>
              Create User Profile
            </PageTitle>
            <button
              onClick={goBack}
              style={{ marginBottom: "15px" }}
              className="btn btn-sm btn-info px-6 align-self-center text-nowrap"
            >
              <i className="bi bi-reply fs-4 me-2"></i> Go Back
            </button>
            <AddProfile />
          </>
        }
      />

      <Route
        path="/view/:id"
        element={
          <>
            <PageTitle breadcrumbs={UserProfilesBreadcrumbs}>
              User Profile View
            </PageTitle>
            <button
              onClick={goBack}
              style={{ marginBottom: "15px" }}
              className="btn btn-sm btn-info px-6 align-self-center text-nowrap"
            >
              <i className="bi bi-reply fs-4 me-2"></i> Go Back
            </button>
            <ProfileHeader />
            <UserProfileView />
          </>
        }
      />

      <Route
        path="/education/:id"
        element={
          <>
            <PageTitle breadcrumbs={UserProfilesBreadcrumbs}>
              User Education
            </PageTitle>
            <button
              onClick={goBack}
              style={{ marginBottom: "15px" }}
              className="btn btn-sm btn-info px-6 align-self-center text-nowrap"
            >
              <i className="bi bi-reply fs-4 me-2"></i> Go Back
            </button>
            <ProfileHeader />
            <ViewEducation />
          </>
        }
      />

      <Route
        path="/skills/:id"
        element={
          <>
            <PageTitle breadcrumbs={UserProfilesBreadcrumbs}>
              User Skills
            </PageTitle>
            <button
              onClick={goBack}
              style={{ marginBottom: "15px" }}
              className="btn btn-sm btn-info px-6 align-self-center text-nowrap"
            >
              <i className="bi bi-reply fs-4 me-2"></i> Go Back
            </button>
            <ProfileHeader />
            <ViewSkills />
          </>
        }
      />

      <Route
        path="/work-experience/:id"
        element={
          <>
            <PageTitle breadcrumbs={UserProfilesBreadcrumbs}>
              Work Experience
            </PageTitle>
            <button
              onClick={goBack}
              style={{ marginBottom: "15px" }}
              className="btn btn-sm btn-info px-6 align-self-center text-nowrap"
            >
              <i className="bi bi-reply fs-4 me-2"></i> Go Back
            </button>
            <ProfileHeader />
            <ViewWorkExperience />
          </>
        }
      />

      <Route
        path="/work-organisation/:id"
        element={
          <>
            <PageTitle breadcrumbs={UserProfilesBreadcrumbs}>
              User Work Organisation
            </PageTitle>
            <button
              onClick={goBack}
              style={{ marginBottom: "15px" }}
              className="btn btn-sm btn-info px-6 align-self-center text-nowrap"
            >
              <i className="bi bi-reply fs-4 me-2"></i> Go Back
            </button>
            <ProfileHeader />
            <ViewWorkOrganisation />
          </>
        }
      />

      <Route
        path="/edit/:id"
        element={
          <>
            <PageTitle breadcrumbs={UserProfilesBreadcrumbs}>
              User Profile Edit
            </PageTitle>
            <button
              onClick={goBack}
              style={{ marginBottom: "15px" }}
              className="btn btn-sm btn-info px-6 align-self-center text-nowrap"
            >
              <i className="bi bi-reply fs-4 me-2"></i> Go Back
            </button>
            <EditBasicDetailsWrapper />
          </>
        }
      />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default ProfilePage;
