/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {getRolewiseRoutes} from './_requests'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const [appRouteAccess, setappRouteAccess] = useState<any>([])
  useEffect(() => {
    getRolewiseRoutes(currentUser?.roleId).then((res: any) => {
      var crudres = res.data?.Data[0]
      setappRouteAccess(crudres)
    })
  }, [])
  const aRA = appRouteAccess?.module?.link
  const GotoRoute = aRA ? `/${aRA}` : null
  //console.log("ttt",);
  var Gotoo = ''
  if (currentUser?.type == '3' || currentUser?.Type == 'clinic') {
    var Gotoo = `${GotoRoute}`
  }
  if (currentUser?.type == '1' || currentUser?.Type == 'adminEmployee') {
    var Gotoo = `${GotoRoute}`
  }
  if (currentUser?.type == '2' || currentUser?.Type == 'client') {
    var Gotoo = `${GotoRoute}`
  }
  //console.log("Gotoo",Gotoo)
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to={Gotoo} />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
