import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'

const UserProfileListToolbar = () => {
  const navigate = useNavigate()
 
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
    
    </div>
  )
}

export {UserProfileListToolbar}
