import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {UserProfile, UserProfileQueryResponse} from './_models'
import { getToken } from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL

const GET_USERPROFILE_URL = `${API_URL}/category/list-user-profile`
const VIEW_USERCATEGORY_URL = `${API_URL}/category/view`
const VIEW_USERPROFILE_URL = `${API_URL}/category/user-view`
const DELETE_USERPROFILE_URL = `${API_URL}/users/delete`

//AuthVerify
var AuthVerify=getToken.call(this);

//UserProfile LIST
const getUserProfile = (query: string): Promise<UserProfileQueryResponse> => {
  // console.log("query",query)
  return axios
    .post(`${GET_USERPROFILE_URL}?${query}`,AuthVerify)
    .then((response: AxiosResponse<UserProfileQueryResponse>) =>{
      return response.data;
    })
}

//UserProfile VEIW
const viewUserProfile = (id: any) => {
  // console.log("id-ap[i==========",id)
  // console.log("VIEW_USERPROFILE_URL",VIEW_USERPROFILE_URL);
  return axios
    .get(`${VIEW_USERPROFILE_URL}/${id}`,getToken())
    .then((response) => {
      // console.log("viewUserProfile--apiii--response",response)
    return response.data;
    }
    )
}
//Category VEIW
const viewUserCategory = (id: any) => {
  // console.log("id-ap[i",id)
  return axios
    .get(`${VIEW_USERCATEGORY_URL}/${id}`,getToken())
    .then((response) => {
    return response.data;
    }
    )
}


//UserProfile DELETE
const deleteUserProfileById = (id: any) => {
  return axios
    .post(`${DELETE_USERPROFILE_URL}/${id}`, AuthVerify)
    .then((response) => {
    return response.data;
    }
    )
}

export {getUserProfile, viewUserProfile, viewUserCategory,deleteUserProfileById}
