import React, { FC, useContext, useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../auth";
import { AlertContext } from "../../../../../_metronic/layout/components/alert-context";
import { UserProfile } from "../user-profile-list/core/_models";
import { viewUserProfile } from "../user-profile-list/core/_requests";

interface SkilsDetail {
  name: string;
  preficiency: string;
}

interface Languages {
  name: string;
  read: boolean;
  write: boolean;
  speak: boolean;
}

interface Technologies {
  name: string;
  workedFor: string;
}

export function ViewSkills() {
  const { id } = useParams();
  // const [profile, setProfile] = useState<any>([]);
  const [techno, setTechnology] = useState<Technologies[]>([]);
  const [skill, setSkills] = useState<SkilsDetail[]>([]);
  const [languages, setLanguages] = useState<Languages[]>([]);

  useEffect(() => {
    viewUserProfile(id).then((res: any) => {
      // console.log("ProfileHeaderView====", res.Data.skills);
      setSkills(res.Data.skills || []);
      setLanguages(res.Data.languages || []);
      setTechnology(res.Data.technologies || []);
    });
  }, []);

  // console.log("profile=====Skills==", techno);

  return (
    <>
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-header cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Skills</h3>
          </div>
        </div>
        <div className="card-body p-9">
          {skill.length > 0 ? (
            skill.map((sk, index) => (
              <div key={index}>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">Name</label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">{sk?.name}</span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Preficiency
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {sk?.preficiency}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No records.</p>
          )}
        </div>

        <div className="card-header cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Languages</h3>
          </div>
        </div>
        <div className="card-body p-9">
          {languages.length > 0 ? (
            languages.map((lang, index) => (
              <div key={index}>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    {lang?.name}
                  </label>
                  <div className="col-lg-8">
                    {/* <span className="fw-bolder fd-6 text-dark">
                      {lang?.read == true ? "read" : ""}
                      {lang?.write == true ? ", write" : ""}
                      {lang?.speak == true ? ", speak" : ""}
                    </span> */}
                    <div className="language-skills d-flex">
                      <div className="me-3">
                        <label>
                          <input 
                            type="radio"
                            className="mx-2"
                            checked={lang?.read === true}
                            disabled={true} />
                          Read
                        </label>
                      </div>
                      <div className="me-3 mx-3">
                        <label>
                          <input
                            type="radio"
                            className="mx-2"
                            checked={lang?.write === true}
                            disabled={true} />
                          Write
                        </label>
                      </div>
                      <div className="mx-3">
                        <label>
                          <input
                            type="radio"
                            className="mx-2"
                            checked={lang?.speak === true}
                            disabled={true}
                          />
                          Speak
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No records.</p>
          )}
        </div>

        <div className="card-header cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Technologies</h3>
          </div>
        </div>
        <div className="card-body p-9">
          {techno.length > 0 ? (
            techno.map((tech, index) => (
              <div key={index}>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">Name</label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {tech?.name}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold-6 text-muted">
                    Work For
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fd-6 text-dark">
                      {tech?.workedFor ? tech?.workedFor + " Years" : ""}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No records.</p>
          )}
        </div>
      </div>
    </>
  );
}
