import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
// import {fetchProfile, updateProfile} from '../_requests'
import {removeAuth, useAuth} from '../../../../../../app/modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useNavigate} from 'react-router-dom'
import {AlertContext} from '../../../../../../_metronic/layout/components/alert-context'
import {fetchProfile, updateProfile} from '../_requests'

const profileDetailsSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  name: Yup.string().required('Name is required'),
  password: Yup.string(),
})

const ProfileDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const [data, setData] = useState<IProfileDetails>(initialValues)

  const {currentUser} = useAuth()
  let passid = currentUser?.userId
  if (!passid) {
    passid = currentUser?.userId
  }
  useEffect(() => {
    passid &&
      fetchProfile(passid).then((res: any) => {
        setData(res.data.Data[0])
      })
  }, [passid])

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const formik = useFormik<IProfileDetails>({
    initialValues: data,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        values.id = data.id
        const updatedData = Object.assign(data, values)
        console.log('up', updatedData)
        updateProfile(updatedData).then((res) => {
          showAlert(res?.Message, res?.Code)
          navigate('/profile/list', {replace: true})
          // localStorage.setItem("PPshowAlert","null");
        })
        setLoading(false)
      }, 1000)
    },
    enableReinitialize: true,
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className=' '>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter your name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Username'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
            {/* 
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Password</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Password'
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div> */}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
