import axios, { AxiosResponse } from 'axios'


const API_URL = process.env.REACT_APP_API_URLS
const RA_URL = `${API_URL}/roleAccess`
 
const getRolewiseRoutes = (roleId: any) => {
  
  return axios
  .get(`${RA_URL}/getRolewiseRoute/${roleId}`)
  .then((response) => {return response})
}
 

export { getRolewiseRoutes }
