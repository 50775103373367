import React, { useContext, useEffect } from 'react'
import {Navigate, Route, Routes, Outlet, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import {Settings} from './components/settings/Settings'
import {Changepassword} from './components/settings/cards/Changepassword'
import { AlertBox } from '../../../_metronic/layout/components/alert-message'
import { AlertContext } from '../../../_metronic/layout/components/alert-context'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Change Password',
    path: '/changePassword',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PasswordPage: React.FC = () => {
  const {show,message,type}=useContext(AlertContext)
  return (
    
    <Routes>
      <Route
        element={
          <>
            {/* <AccountHeader /> */}
            <Outlet />
          </>
        }
      >
       
         <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Change Password</PageTitle>
              {show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}
              <Changepassword />
            </>
          }
        />
         <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default PasswordPage
