import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UserProfileListHeader} from './components/header/UserProfileListHeader'
import {UserProfileTable} from './table/UserProfileTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
import {useContext} from 'react'

const UserProfileList = () => {
  return (
    <>
      <KTCard>
        <UserProfileListHeader />
        <UserProfileTable />
      </KTCard>
    </>
  )
}
const UserProfileMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />}
      <div className='col-lg-12'>
        <UserProfileList />
      </div>
    </div>
  )
}
const UserProfileListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UserProfileMain />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UserProfileListWrapper}
