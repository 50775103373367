import React, {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {UserProfile} from '../user-profile-list/core/_models'
import {viewUserProfile} from '../user-profile-list/core/_requests'

const imageUrl = process.env.REACT_APP_IMAGE_UPLOADS

const UserProfileView: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const [role, setRole] = useState<any>([])
  const [profile, setProfile] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [formImage, setFormImage] = useState<File | null>(null)

  //Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  useEffect(() => {
    viewUserProfile(id).then((res: any) => {
      // console.log('ProfileHeaderView====', res)
      setProfile(res.Data)
    })
  }, [])

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'> User Profile Details</h3>
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Institution</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{profile.institution}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Gender</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {profile.gender == 1 ? 'male' : 'female'}
              </span>
            </div>
          </div>
          
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Address</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {profile.address? profile.address : ""  +
                  ',' +
                  profile.city? profile.city :"" +
                  ',' +
                  profile.state ?  profile.state : "" +
                  ',' +
                  profile.country? profile.country : "" +
                  ',' +
                  profile.pincode? profile.pincode : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserProfileView
