/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { UserProfile } from "../../core/_models";
import Avatar from "react-avatar";

type Props = {
  userprofiles: UserProfile;
  usertype: UserProfile;
  usercreateprofile: UserProfile;
};

const UserProfileInfoCell: FC<Props> = ({ userprofiles }) => {
  // console.log("imagescheck======", userprofiles);
  return (
    <div className="align-items-center">
      {/* begin:: Avatar */}
      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
        <a href="#">
          {/* {userprofiles?.profile ? ( */}
            {/* <div className="symbol-label">
              <img
                src={userprofiles?.profile}
                alt="ProfileImage"
                style={{ objectFit: "contain" }}
                className="w-100"
              />
            </div> */}
          {/* ) : ( */}
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <Avatar
                size="50"
                name={userprofiles?.firstName}
                maxInitials={2}
                round="20px"
                color="#607458"
                fgColor="#1b1b29"
                className="fs-2"
              ></Avatar>
            </div>
           {/* )} */}
        </a>
      </div>
      <div className="d-flex flex-column">
        <a href="#" className="text-gray-800 text-hover-primary mb-1">
          {userprofiles?.firstName}
          {userprofiles?.lastName}
        </a>
        <span>{userprofiles.email}</span>
      </div>
    </div>
  );
};

const UserType: FC<Props> = ({ usertype }) => (
  <div className="d-flex align-items-center">
    <div className="d-flex flex-column">
      <a href="#" className="text-gray-800 text-hover-primary mb-1">
        {usertype?.categoryId === 1 ? (
          <a className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3">
            Fresher
          </a>
        ) : usertype?.categoryId === 2 ? (
          <a className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3">
            Experience
          </a>
        ) : (
          ""
        )}
      </a>
    </div>
  </div>
);

const CreateProfileDate: FC<Props> = ({ usercreateprofile }) => {
  function formatDate(dateString: any) {
    if (!dateString) return "";

    const date = new Date(dateString);
    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  }
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column">
        <span>
          {usercreateprofile && formatDate(usercreateprofile?.createdAt)}
        </span>
      </div>
    </div>
  );
};

export { UserProfileInfoCell, UserType, CreateProfileDate };
